const countryList = [
  {
    "id": 1,
    "area_name": "中国大陆",
    "national_code": 86
  },
  {
    "id": 2,
    "area_name": "中国香港",
    "national_code": 852
  },
  {
    "id": 3,
    "area_name": "中国澳门",
    "national_code": 853
  },
  {
    "id": 4,
    "area_name": "中国台湾",
    "national_code": 886
  },
  {
    "id": 5,
    "area_name": "United States",
    "national_code": 1
  },
  {
    "id": 6,
    "area_name": "Japan",
    "national_code": 81
  },
  {
    "id": 7,
    "area_name": "Korea (South)",
    "national_code": 82
  },
  {
    "id": 8,
    "area_name": "Singapore",
    "national_code": 65
  },
  {
    "id": 9,
    "area_name": "Canada",
    "national_code": 1
  },
  {
    "id": 10,
    "area_name": "Australia",
    "national_code": 61
  },
  {
    "id": 11,
    "area_name": "Albania",
    "national_code": 355
  },
  {
    "id": 12,
    "area_name": "Algeria",
    "national_code": 213
  },
  {
    "id": 13,
    "area_name": "Afghanistan",
    "national_code": 93
  },
  {
    "id": 14,
    "area_name": "Argentina",
    "national_code": 54
  },
  {
    "id": 15,
    "area_name": "United Arab Emirates",
    "national_code": 971
  },
  {
    "id": 16,
    "area_name": "Aruba",
    "national_code": 297
  },
  {
    "id": 17,
    "area_name": "Oman",
    "national_code": 968
  },
  {
    "id": 18,
    "area_name": "Azerbaijan",
    "national_code": 994
  },
  {
    "id": 19,
    "area_name": "Egypt",
    "national_code": 20
  },
  {
    "id": 20,
    "area_name": "Ethiopia",
    "national_code": 251
  },
  {
    "id": 21,
    "area_name": "Ireland",
    "national_code": 353
  },
  {
    "id": 22,
    "area_name": "Estonia",
    "national_code": 372
  },
  {
    "id": 23,
    "area_name": "Andorra",
    "national_code": 376
  },
  {
    "id": 24,
    "area_name": "Angola",
    "national_code": 244
  },
  {
    "id": 25,
    "area_name": "Anguilla",
    "national_code": 1264
  },
  {
    "id": 26,
    "area_name": "Antigua and Barbuda",
    "national_code": 268
  },
  {
    "id": 27,
    "area_name": "Austria",
    "national_code": 43
  },
  {
    "id": 28,
    "area_name": "Barbados",
    "national_code": 1246
  },
  {
    "id": 29,
    "area_name": "Guinea",
    "national_code": 224
  },
  {
    "id": 30,
    "area_name": "Papua New Guinea",
    "national_code": 675
  },
  {
    "id": 31,
    "area_name": "Bahamas",
    "national_code": 1242
  },
  {
    "id": 32,
    "area_name": "Pakistan",
    "national_code": 92
  },
  {
    "id": 33,
    "area_name": "Paraguay",
    "national_code": 595
  },
  {
    "id": 34,
    "area_name": "Palestine",
    "national_code": 970
  },
  {
    "id": 35,
    "area_name": "Bahrain",
    "national_code": 973
  },
  {
    "id": 36,
    "area_name": "Panama",
    "national_code": 507
  },
  {
    "id": 37,
    "area_name": "Brazil",
    "national_code": 55
  },
  {
    "id": 38,
    "area_name": "Belarus",
    "national_code": 375
  },
  {
    "id": 39,
    "area_name": "Bermuda",
    "national_code": 1441
  },
  {
    "id": 40,
    "area_name": "Bulgaria",
    "national_code": 359
  },
  {
    "id": 41,
    "area_name": "Northern Mariana Islands",
    "national_code": 167
  },
  {
    "id": 42,
    "area_name": "Benin",
    "national_code": 229
  },
  {
    "id": 43,
    "area_name": "Belgium",
    "national_code": 32
  },
  {
    "id": 44,
    "area_name": "Iceland",
    "national_code": 354
  },
  {
    "id": 45,
    "area_name": "Puerto Rico",
    "national_code": 1787
  },
  {
    "id": 46,
    "area_name": "Puerto Rico",
    "national_code": 1939
  },
  {
    "id": 47,
    "area_name": "Poland",
    "national_code": 48
  },
  {
    "id": 48,
    "area_name": "Bosnia and Herzegovina",
    "national_code": 387
  },
  {
    "id": 49,
    "area_name": "Bolivia",
    "national_code": 591
  },
  {
    "id": 50,
    "area_name": "Belize",
    "national_code": 501
  },
  {
    "id": 51,
    "area_name": "Botswana",
    "national_code": 267
  },
  {
    "id": 52,
    "area_name": "Bhutan",
    "national_code": 975
  },
  {
    "id": 53,
    "area_name": "Burkina Faso",
    "national_code": 226
  },
  {
    "id": 54,
    "area_name": "Burundi",
    "national_code": 257
  },
  {
    "id": 55,
    "area_name": "Korea (North)",
    "national_code": 850
  },
  {
    "id": 56,
    "area_name": "Equatorial Guinea",
    "national_code": 240
  },
  {
    "id": 57,
    "area_name": "Denmark",
    "national_code": 45
  },
  {
    "id": 58,
    "area_name": "Germany",
    "national_code": 49
  },
  {
    "id": 59,
    "area_name": "Timor-Leste",
    "national_code": 670
  },
  {
    "id": 60,
    "area_name": "Togo",
    "national_code": 228
  },
  {
    "id": 61,
    "area_name": "Dominican Republic",
    "national_code": 1809
  },
  {
    "id": 62,
    "area_name": "Dominican",
    "national_code": 1767
  },
  {
    "id": 63,
    "area_name": "Russian",
    "national_code": 7
  },
  {
    "id": 64,
    "area_name": "Ecuador",
    "national_code": 593
  },
  {
    "id": 65,
    "area_name": "Eritrea",
    "national_code": 291
  },
  {
    "id": 66,
    "area_name": "France",
    "national_code": 33
  },
  {
    "id": 67,
    "area_name": "Faroe Islands",
    "national_code": 298
  },
  {
    "id": 68,
    "area_name": "French Polynesia",
    "national_code": 689
  },
  {
    "id": 69,
    "area_name": "French Guiana",
    "national_code": 594
  },
  {
    "id": 70,
    "area_name": "Vatican City",
    "national_code": 379
  },
  {
    "id": 71,
    "area_name": "Philippines",
    "national_code": 63
  },
  {
    "id": 72,
    "area_name": "Fiji",
    "national_code": 679
  },
  {
    "id": 73,
    "area_name": "Finland",
    "national_code": 358
  },
  {
    "id": 74,
    "area_name": "Cape Verde",
    "national_code": 238
  },
  {
    "id": 75,
    "area_name": "Gambia",
    "national_code": 220
  },
  {
    "id": 76,
    "area_name": "Congo",
    "national_code": 242
  },
  {
    "id": 77,
    "area_name": "Congo (Congo-Kinshasa)",
    "national_code": 243
  },
  {
    "id": 78,
    "area_name": "Colombia",
    "national_code": 57
  },
  {
    "id": 79,
    "area_name": "Costa Rica",
    "national_code": 506
  },
  {
    "id": 80,
    "area_name": "Grenada",
    "national_code": 1473
  },
  {
    "id": 81,
    "area_name": "Greenland",
    "national_code": 299
  },
  {
    "id": 82,
    "area_name": "Georgia",
    "national_code": 995
  },
  {
    "id": 83,
    "area_name": "Cuba",
    "national_code": 53
  },
  {
    "id": 84,
    "area_name": "Guadeloupe",
    "national_code": 590
  },
  {
    "id": 85,
    "area_name": "Guam",
    "national_code": 1671
  },
  {
    "id": 86,
    "area_name": "Guyana",
    "national_code": 592
  },
  {
    "id": 87,
    "area_name": "Kazakhstan",
    "national_code": 7
  },
  {
    "id": 88,
    "area_name": "Haiti",
    "national_code": 509
  },
  {
    "id": 89,
    "area_name": "Netherlands",
    "national_code": 31
  },
  {
    "id": 90,
    "area_name": "Netherlands Antilles",
    "national_code": 599
  },
  {
    "id": 91,
    "area_name": "Montenegro",
    "national_code": 382
  },
  {
    "id": 92,
    "area_name": "Honduras",
    "national_code": 504
  },
  {
    "id": 93,
    "area_name": "Kiribati",
    "national_code": 686
  },
  {
    "id": 94,
    "area_name": "Djibouti",
    "national_code": 253
  },
  {
    "id": 95,
    "area_name": "Kyrgyzstan",
    "national_code": 996
  },
  {
    "id": 96,
    "area_name": "Guinea-Bissau",
    "national_code": 245
  },
  {
    "id": 97,
    "area_name": "Ghana",
    "national_code": 233
  },
  {
    "id": 98,
    "area_name": "Gabon",
    "national_code": 241
  },
  {
    "id": 99,
    "area_name": "Cambodia",
    "national_code": 855
  },
  {
    "id": 100,
    "area_name": "Czech",
    "national_code": 420
  },
  {
    "id": 101,
    "area_name": "Zimbabwe",
    "national_code": 263
  },
  {
    "id": 102,
    "area_name": "Cameroon",
    "national_code": 237
  },
  {
    "id": 103,
    "area_name": "Qatar",
    "national_code": 974
  },
  {
    "id": 104,
    "area_name": "Cayman Islands",
    "national_code": 1345
  },
  {
    "id": 105,
    "area_name": "Comoros",
    "national_code": 269
  },
  {
    "id": 106,
    "area_name": "Cote D'Ivoire",
    "national_code": 225
  },
  {
    "id": 107,
    "area_name": "Kuwait",
    "national_code": 965
  },
  {
    "id": 108,
    "area_name": "Croatia",
    "national_code": 385
  },
  {
    "id": 109,
    "area_name": "Kenya",
    "national_code": 254
  },
  {
    "id": 110,
    "area_name": "Cook Islands",
    "national_code": 682
  },
  {
    "id": 111,
    "area_name": "Latvia",
    "national_code": 371
  },
  {
    "id": 112,
    "area_name": "Lesotho",
    "national_code": 266
  },
  {
    "id": 113,
    "area_name": "Laos",
    "national_code": 856
  },
  {
    "id": 114,
    "area_name": "Lebanon",
    "national_code": 961
  },
  {
    "id": 115,
    "area_name": "Lithuania",
    "national_code": 370
  },
  {
    "id": 116,
    "area_name": "Liberia",
    "national_code": 231
  },
  {
    "id": 117,
    "area_name": "Libya",
    "national_code": 218
  },
  {
    "id": 118,
    "area_name": "Liechtenstein",
    "national_code": 423
  },
  {
    "id": 119,
    "area_name": "Luxembourg",
    "national_code": 352
  },
  {
    "id": 120,
    "area_name": "Rwanda",
    "national_code": 250
  },
  {
    "id": 121,
    "area_name": "Romania",
    "national_code": 40
  },
  {
    "id": 122,
    "area_name": "Madagascar",
    "national_code": 261
  },
  {
    "id": 123,
    "area_name": "Maldives",
    "national_code": 960
  },
  {
    "id": 124,
    "area_name": "Malta",
    "national_code": 356
  },
  {
    "id": 125,
    "area_name": "Malawi",
    "national_code": 265
  },
  {
    "id": 126,
    "area_name": "Malaysia",
    "national_code": 60
  },
  {
    "id": 127,
    "area_name": "Mali",
    "national_code": 223
  },
  {
    "id": 128,
    "area_name": "Macedonia",
    "national_code": 389
  },
  {
    "id": 129,
    "area_name": "Marshall Islands",
    "national_code": 692
  },
  {
    "id": 130,
    "area_name": "Mayotte",
    "national_code": 262
  },
  {
    "id": 131,
    "area_name": "Mauritius",
    "national_code": 230
  },
  {
    "id": 132,
    "area_name": "Mauritania",
    "national_code": 222
  },
  {
    "id": 133,
    "area_name": "American Samoa",
    "national_code": 684
  },
  {
    "id": 134,
    "area_name": "U.S. Virgin Islands",
    "national_code": 1340
  },
  {
    "id": 135,
    "area_name": "Mongolia",
    "national_code": 976
  },
  {
    "id": 136,
    "area_name": "Montserrat",
    "national_code": 1664
  },
  {
    "id": 137,
    "area_name": "Bangladesh",
    "national_code": 880
  },
  {
    "id": 138,
    "area_name": "Peru",
    "national_code": 51
  },
  {
    "id": 139,
    "area_name": "Micronesia",
    "national_code": 691
  },
  {
    "id": 140,
    "area_name": "Myanmar",
    "national_code": 95
  },
  {
    "id": 141,
    "area_name": "Moldova",
    "national_code": 373
  },
  {
    "id": 142,
    "area_name": "Morocco",
    "national_code": 212
  },
  {
    "id": 143,
    "area_name": "Monaco",
    "national_code": 377
  },
  {
    "id": 144,
    "area_name": "Mozambique",
    "national_code": 258
  },
  {
    "id": 145,
    "area_name": "Mexico",
    "national_code": 52
  },
  {
    "id": 146,
    "area_name": "Namibia",
    "national_code": 264
  },
  {
    "id": 147,
    "area_name": "South Africa",
    "national_code": 27
  },
  {
    "id": 148,
    "area_name": "Nauru",
    "national_code": 674
  },
  {
    "id": 149,
    "area_name": "Nepal",
    "national_code": 977
  },
  {
    "id": 150,
    "area_name": "Nicaragua",
    "national_code": 505
  },
  {
    "id": 151,
    "area_name": "Niger",
    "national_code": 227
  },
  {
    "id": 152,
    "area_name": "Nigeria",
    "national_code": 234
  },
  {
    "id": 153,
    "area_name": "Niue",
    "national_code": 683
  },
  {
    "id": 154,
    "area_name": "Norway",
    "national_code": 47
  },
  {
    "id": 155,
    "area_name": "Portugal",
    "national_code": 351
  },
  {
    "id": 156,
    "area_name": "Sweden",
    "national_code": 46
  },
  {
    "id": 157,
    "area_name": "Switzerland",
    "national_code": 41
  },
  {
    "id": 158,
    "area_name": "El Salvador",
    "national_code": 503
  },
  {
    "id": 159,
    "area_name": "Samoa",
    "national_code": 685
  },
  {
    "id": 160,
    "area_name": "Serbia",
    "national_code": 381
  },
  {
    "id": 161,
    "area_name": "Sierra Leone",
    "national_code": 232
  },
  {
    "id": 162,
    "area_name": "Senegal",
    "national_code": 221
  },
  {
    "id": 163,
    "area_name": "Cyprus",
    "national_code": 357
  },
  {
    "id": 164,
    "area_name": "Seychelles",
    "national_code": 248
  },
  {
    "id": 165,
    "area_name": "Saudi Arabia",
    "national_code": 966
  },
  {
    "id": 166,
    "area_name": "Sao Tome and Principe",
    "national_code": 239
  },
  {
    "id": 167,
    "area_name": "Saint Kitts-Nevis",
    "national_code": 1869
  },
  {
    "id": 168,
    "area_name": "Saint Lucia",
    "national_code": 1758
  },
  {
    "id": 169,
    "area_name": "San Marino",
    "national_code": 378
  },
  {
    "id": 170,
    "area_name": "Saint Vincent and the Grenadines",
    "national_code": 1784
  },
  {
    "id": 171,
    "area_name": "Falkland Islands",
    "national_code": 500
  },
  {
    "id": 172,
    "area_name": "Sri Lanka",
    "national_code": 94
  },
  {
    "id": 173,
    "area_name": "Slovakia",
    "national_code": 421
  },
  {
    "id": 174,
    "area_name": "Slovenia",
    "national_code": 386
  },
  {
    "id": 175,
    "area_name": "Sudan",
    "national_code": 249
  },
  {
    "id": 176,
    "area_name": "Suriname",
    "national_code": 597
  },
  {
    "id": 177,
    "area_name": "Solomon Islands",
    "national_code": 677
  },
  {
    "id": 178,
    "area_name": "Somalia",
    "national_code": 252
  },
  {
    "id": 179,
    "area_name": "Tajikistan",
    "national_code": 992
  },
  {
    "id": 180,
    "area_name": "Thailand",
    "national_code": 66
  },
  {
    "id": 181,
    "area_name": "Tanzania",
    "national_code": 255
  },
  {
    "id": 182,
    "area_name": "Tonga",
    "national_code": 676
  },
  {
    "id": 183,
    "area_name": "Turks and Caicos Islands",
    "national_code": 1649
  },
  {
    "id": 184,
    "area_name": "Trinidad and Tobago",
    "national_code": 1868
  },
  {
    "id": 185,
    "area_name": "Tunisia",
    "national_code": 216
  },
  {
    "id": 186,
    "area_name": "Turkey",
    "national_code": 90
  },
  {
    "id": 187,
    "area_name": "Turkmenistan",
    "national_code": 993
  },
  {
    "id": 188,
    "area_name": "Tuvalu",
    "national_code": 688
  },
  {
    "id": 189,
    "area_name": "Tokelau",
    "national_code": 690
  },
  {
    "id": 190,
    "area_name": "Vanuatu",
    "national_code": 678
  },
  {
    "id": 191,
    "area_name": "Guatemala",
    "national_code": 502
  },
  {
    "id": 192,
    "area_name": "Venezuela",
    "national_code": 58
  },
  {
    "id": 193,
    "area_name": "Brunei",
    "national_code": 673
  },
  {
    "id": 194,
    "area_name": "Wallis and Futuna Islands",
    "national_code": 681
  },
  {
    "id": 195,
    "area_name": "Uganda",
    "national_code": 256
  },
  {
    "id": 196,
    "area_name": "Ukraine",
    "national_code": 380
  },
  {
    "id": 197,
    "area_name": "Uruguay",
    "national_code": 598
  },
  {
    "id": 198,
    "area_name": "Uzbekistan",
    "national_code": 998
  },
  {
    "id": 199,
    "area_name": "Spain",
    "national_code": 34
  },
  {
    "id": 200,
    "area_name": "Greece",
    "national_code": 30
  },
  {
    "id": 201,
    "area_name": "New Caledonia",
    "national_code": 687
  },
  {
    "id": 202,
    "area_name": "New Zealand",
    "national_code": 64
  },
  {
    "id": 203,
    "area_name": "Hungary",
    "national_code": 36
  },
  {
    "id": 204,
    "area_name": "Syria",
    "national_code": 963
  },
  {
    "id": 205,
    "area_name": "Jamaica",
    "national_code": 1876
  },
  {
    "id": 206,
    "area_name": "Armenia",
    "national_code": 374
  },
  {
    "id": 207,
    "area_name": "Yemen",
    "national_code": 967
  },
  {
    "id": 208,
    "area_name": "Iraq",
    "national_code": 964
  },
  {
    "id": 209,
    "area_name": "Iran",
    "national_code": 98
  },
  {
    "id": 210,
    "area_name": "Israel",
    "national_code": 972
  },
  {
    "id": 211,
    "area_name": "Italy",
    "national_code": 39
  },
  {
    "id": 212,
    "area_name": "India",
    "national_code": 91
  },
  {
    "id": 213,
    "area_name": "Indonesia",
    "national_code": 62
  },
  {
    "id": 214,
    "area_name": "United Kingdom",
    "national_code": 44
  },
  {
    "id": 215,
    "area_name": "British Virgin Islands",
    "national_code": 1284
  },
  {
    "id": 216,
    "area_name": "Jordan",
    "national_code": 962
  },
  {
    "id": 217,
    "area_name": "Vietnam",
    "national_code": 84
  },
  {
    "id": 218,
    "area_name": "Zambia",
    "national_code": 260
  },
  {
    "id": 219,
    "area_name": "Chad",
    "national_code": 235
  },
  {
    "id": 220,
    "area_name": "Gibraltar",
    "national_code": 350
  },
  {
    "id": 221,
    "area_name": "Chile",
    "national_code": 56
  },
  {
    "id": 222,
    "area_name": "Central African Republic",
    "national_code": 236
  }
];

export default countryList

